<div class="w-[88.5%] m-auto">
	<div class="flex ltr:justify-end rtl:justify-start mb-2">
		<img
			class="ltr:ml-[97%] rtl:mr-[97%] cursor-pointer"
			src="assets/images/close-square.svg"
			alt=""
			(click)="dialogRef.close()"
		/>
	</div>
	<div
		*ngIf="!croppedImage?.croppedMobile"
		class="text-center rounded-[6px] border-[#D9DBE9] border pb-[16px] mb-[18px]"
	>
		<button class="mt-[18px] mb-12px">
			<label class="custom-file-upload cursor-pointer">
				<input
					#imgInput
					class="cursor-pointer"
					type="file"
					(change)="fileChangeEvent($event)"
				/>
				<img class="m-auto" src="./assets/images/upload.svg" alt="" />
				<h1 class="text-[#007AFF] text-font-16 font-normal leading-[24px] m-0">
					{{ 'ACTIONS.UPLOAD_IMG' | translate }}
				</h1>
			</label>
		</button>
	</div>

	<div
		class="relative text-center w-[300px] m-auto mb-12px"
		*ngIf="croppedImage?.croppedMobile"
	>
		<span
			class="absolute top-3 rtl:left-3 ltr:right-3 cursor-pointer"
			(click)="deleteImage()"
		>
		</span>
		<img
			class="w-full rounded-[12px] bg-contain"
			[src]="croppedImage?.croppedMobile"
			alt=""
		/>
	</div>
	<small *ngIf="sizeError" class="text-red-error">
		{{ 'ERROR_MESSAGES.IMAGE_SIZE_2MB' | translate }}
	</small>
	<small *ngIf="typeError" class="text-red-error">
		{{ 'ERROR_MESSAGES.INVALID_IMAGE_TYPE' | translate }}
	</small>
	<button
		mat-button
		[disabled]="!croppedImage?.croppedMobile"
		[mat-dialog-close]="{
			selectedImage: croppedImage,
			selectedFile: uploadedFile
		}"
		class="w-100 bg-gradient text-white w-full rounded-[6px]"
	>
		{{ 'ACTIONS.UPLOAD_IMG' | translate }}
	</button>
</div>

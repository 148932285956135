import { Component, EventEmitter, Input, Output } from '@angular/core'
import { SharedService } from '../../services/shared.service'
import { Subscription } from 'rxjs'
import { environment } from 'src/environments/environment'
import { TranslateService } from '@ngx-translate/core'

@Component({
	selector: 'app-countries-tabs',
	templateUrl: './countries-tabs.component.html',
	styleUrl: './countries-tabs.component.scss',
})
export class CountriesTabsComponent {
	subscription = new Subscription()
	activeCountries!: any
	attachmentUrl = environment.attachmentPath

	@Output() countryId = new EventEmitter()
	@Input() selectedCountry: number = 224
	constructor(
		private SharedService: SharedService,
		public translate: TranslateService
	) {}
	ngOnInit() {
		this.getActiveCountries()
	}
	getActiveCountries() {
		this.subscription.add(
			this.SharedService.getActiveCountries().subscribe((res: any) => {
				this.activeCountries = res
			})
		)
	}
	countryChange(countryId: number) {
		this.selectedCountry = countryId
		this.countryId.emit(this.selectedCountry)
	}
}

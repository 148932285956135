import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from '@angular/material/dialog'
import { ImageUpload } from '../../models/general.models'

@Component({
	selector: 'app-image-upload-no-cropper',
	templateUrl: './image-upload-no-cropper.component.html',
	styleUrl: './image-upload-no-cropper.component.scss',
})
export class ImageUploadNoCropperComponent {
	@ViewChild('fileInput', { static: false }) fileInput!: ElementRef
	acceptFiles = 'image/png, image/jpeg, image/jpg'
	imageChangedEvent: any = ''
	croppedImage: any = ''
	uploadedFile: any
	sizeError = false
	constructor(
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<ImageUploadNoCropperComponent>,
		@Inject(MAT_DIALOG_DATA) public imageUpload: ImageUpload
	) {}
	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event
		if (
			this.imageChangedEvent?.target?.files[0]?.size <= 1000000 &&
			this.acceptFiles.includes(this.imageChangedEvent.target.files[0].type)
		) {
			console.log(this.imageChangedEvent, 'imageChangedEvent')

			this.sizeError = false
			if (this.imageChangedEvent?.target?.files) {
				let reader = new FileReader()
				reader.readAsDataURL(this.imageChangedEvent?.target?.files[0])
				reader.onload = (event: any) => {
					console.log(event.target.result)

					this.croppedImage = event.target.result
					this.fileRender()
				}
			}

			// const dialogRef = this.dialog.open(ImageCropperComponent, {
			//   width: '500px',
			//   height: '550px',
			//   panelClass: ['overflow-y-auto'],
			//   data: {
			//     imageUpload: this.imageUpload,
			//     imageChangedEvent: this.imageChangedEvent,
			//   },
			// })

			// dialogRef.afterClosed().subscribe((result) => {
			// 	if (result) {
			// 		this.croppedImage = result
			// 		this.fileRender()
			// 	} else {
			// 		this.resetFileInput()
			// 		this.deleteImage()
			// 	}
			// })
		} else this.sizeError = true
	}
	resetFileInput() {
		this.fileInput.nativeElement.value = null
	}
	fileRender() {
		this.uploadedFile = new File(
			[this.dataURItoBlob(this.croppedImage)],
			'image.jpg'
		)
	}

	dataURItoBlob(dataURI: any): Blob {
		const byteString = atob(dataURI.split(',')[1])
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
		const ab = new ArrayBuffer(byteString.length)
		let ia = new Uint8Array(ab)
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i)
		}
		return new Blob([ab], { type: mimeString })
	}

	deleteImage() {
		this.croppedImage = ''
		this.imageChangedEvent = ''
	}
	closeDialog() {
		this.dialogRef.close()
	}
}

<div class="flex p-3" *ngIf="step == 1">
	<img
		class="mt-[5px] self-start"
		[mat-dialog-close]="false"
		src="assets/images/backArrow.svg"
		alt="icon"
	/>
</div>
<div class="p-3" *ngIf="step == 1">
	<image-cropper
		[imageChangedEvent]="data.imageChangedEvent"
		[maintainAspectRatio]="true"
		[aspectRatio]="data.imageUpload.mobile.imageCropDimensions"
		format="png"
		(imageCropped)="imageCropped($event, 'croppedMobile')"
		[resizeToWidth]="
			data.imageUpload.mobile.imageWidth
				? data.imageUpload.mobile.imageWidth
				: 500
		"
	></image-cropper>
	<div class="w-[100%] m-auto p-3">
		<button
			[mat-dialog-close]="croppedImage"
			class="w-100 bg-gradient text-white w-full rounded-[6px] py-[10px]"
		>
			{{ 'ACTIONS.SAVE' | translate }}
		</button>
		<button
			[mat-dialog-close]="false"
			(click)="cancel()"
			class="w-full mt-3 text-gray-800 border border-solid border-[#D9DBE9] mb-12px"
			mat-button
		>
			{{ 'ACTIONS.CANCEL' | translate }}
		</button>
	</div>
</div>

const productionFirebase = {
  apiKey: 'AIzaSyBBrb8OU_rnndP2ucgaKZTYzZRgAj8_uTo',
  authDomain: 'go-care-for-business.firebaseapp.com',
  projectId: 'go-care-for-business',
  storageBucket: 'go-care-for-business.appspot.com',
  messagingSenderId: '853224797328',
  appId: '1:853224797328:web:10df9dffd483a57505d415',
  measurementId: 'G-Z3G087MTG7',
  keyPair:
    'BF7_a9d-8cSpcEvaYAw5n-ZpN6QpdyN5HNAys7nkrS4RljQ98r9gETkcNSRGZD9QN0SB4f3zqnkFCcvpvBWZRA0',
  serverKey:
    'key=AAAAxqgpNJA:APA91bE4Wof4u5-CM_tkkukU_p7ZlakEe4pq4xxISyM23dQGsafZkJJYpMfIJYGzFoD7mvrxLtdbKWzXErvEv2PFhbvLcBHv2aKNFV2GEEbAAHEjJQhPdlUXwAeRv7QV4EVZJpdynmbi',
}

export const environment = {
  production: true,

  baseURL: 'https://system.go-care.com',
  apiUrl: 'https://system.go-care.com/api',
  attachmentPath: 'https://system.go-care.com/Attachments',
  firebaseConfig: { ...productionFirebase },

  googleMapKey: 'AIzaSyA7GuhrFYR_k6lQ1JQvClXX9SEdyg9BpvA',
}

import { Component, Input, OnChanges, OnInit } from '@angular/core'

@Component({
	selector: 'app-status',
	templateUrl: './status.component.html',
	styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit, OnChanges {
	@Input() enumData: { name: string | any; value: string }[] = []
	@Input() statusValue: any = ''
	@Input() ExtraText: any = ''
	redStatus = [
		'INACTIVE',
		'REJECTED',
		'CLOSED',
		'BLOCKED',
		'CANCELLED',
		'UNPAID',
		'CLOSED',
		'RESUBMITTED',
		'PENDING_ORDER',
		'PENDING_SHIPPING_ORDER',
		'DELIVERY_IN_PROGRESS_ORDER',
		'CANCELLED_ORDER',
		'PENDING_REFUND_ORDER',
		'REJECTED',
		'DEDUCTED',
	]
	greenStatus = [
		'ACTIVE',
		'ACCEPTED',
		'COMPLETED',
		'COMPLETE',
		'CONFIRMED',
		'PAID',
		'PAYATSTORE',
		'OPEN',
		'REFUNDED',
		'FAWRY',
		'NEW_G',
		'NEW_PRODUCT',
		'DELIVERED',
		'SHIPPED',
		'NEW_ORDER',
		'CONFIRMED_ORDER',
		'DELIVERED_ORDER',
		'REFUNDED_ORDER',
		'APPROVED',
		'EARNED',
	]
	yellowStatus = [
		'PENDING',
		'UNCONFIRMED',
		'PAYONLINE',
		'NEW',
		'BANKACCOUNT',
		'EDITED',
		'EDITED_PRODUCT',
		'PENDING_EARNED',
		'PENDING_DEDUCTED',
	]
	salonStatus = ['DIDNOTSHOWUP', 'INSTAPAY']

	statusName = ''

	constructor() {}

	ngOnInit(): void {}

	ngOnChanges() {
		this.getStatusName()
	}

	getStatusName() {
		this.statusName = this.enumData
			?.find((obj) => obj.value == this.statusValue)
			?.name.toUpperCase()
	}
}

export const Pattern = {
	// Email
	email: new RegExp('([A-Za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3})$'),
	// Password
	password: new RegExp('^(?=.*[a-z A-Z])(?=.*[0-9])'),
	// mix of letters, numbers, and special characters (minimum of 8 characters)
	password2: new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$'),
	// Phone
	phone: new RegExp('^[0-9]{10}$'),

	// Name
	name: new RegExp('^[a-zA-Zء-ي  ]{1,1000}$'),

	// Email and phone with + code
	emailOrPhone: new RegExp(
		'^([0-9]{10})|([A-Za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3})$'
	),
	// Email and phone without + code
	emailOrPhonePlusNoZero: new RegExp(
		'(^[1-9]{1}[0-9]{9})$|([A-Za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3})$'
	),

	// password max length 50
	passwordMaxLength50: new RegExp(
		'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,50})'
	),
	// numbers only without spaces
	numWithoutSpace: new RegExp(/^([0-9]+-)*([0-9]+)$/),

	// COUNTRIES PHONES WITHOUT CODE PATTERN //

	// phone saudi arabia without code pattern
	phoneSaudiArabiaWithoutCode: new RegExp(/^(1|5)([0-9]{8,9})$/),

	// phone egypt without code pattern
	phoneEgyptWithoutCode: new RegExp(/^(1)([0-9]{9})$/),

	// phone kuwait without code pattern

	phoneKuwaitWithoutCode: new RegExp(/^(5|6|9)([0-9]{7})$/),

	// phone oman without code pattern

	phoneOmanWithoutCode: new RegExp(/^(2|7|9)([0-9]{7})$/),

	// phone qatar without code pattern

	phoneQatarWithoutCode: new RegExp(/^(3|5|6|7|9)([0-9]{7})$/),

	// phone bahrain without code pattern

	phoneBahrainWithoutCode: new RegExp(/^(3|6|8|9)([0-9]{7})$/),

	// phone uae without code pattern

	phoneUaeWithoutCode: new RegExp(/^(5|2|3|6|4|9|1|8|7)([0-9]{8}|[0-9]{9})$/),

	// phone yemen without code pattern
	phoneYemenWithoutCode: new RegExp(/^(1|2|3|4|5|6|7)([0-9]{6})$/),

	// phone morocco without code pattern
	phoneMoroccoWithoutCode: new RegExp(/^(6|7)([0-9]{8})$/),

	// phone libya without code pattern
	phoneLibyaWithoutCode: new RegExp(/^(9)([0-9]{8})$/),

	// phone tunisia without code pattern
	phoneTunisiaWithoutCode: new RegExp(/^(2|9)([0-9]{7})$/),

	// phone algeria without code pattern
	phoneAlgeriaWithoutCode: new RegExp(/^(5|6|7)([0-9]{8})$/),

	// phone jordan without code pattern
	phoneJordanWithoutCode: new RegExp(/^(7|9)([0-9]{8})$/),

	// phone lebanon without code pattern
	phoneLebanonWithoutCode: new RegExp(/^(3|7)([0-9]{8})$/),

	// phone syria without code pattern
	phoneSyriaWithoutCode: new RegExp(/^(9)([0-9]{8})$/),

	// phone iraq without code pattern
	phoneIraqWithoutCode: new RegExp(/^(7)([0-9]{8})$/),

	instagramUrlPattern: new RegExp(
		/(?:http:\/\/)?(?:www\.)?instagram\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/
	),

	facebookUrlPattern: new RegExp(
		/(?:http:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/
	),
	alexandriaLandlinePattern: new RegExp('^03\\d{7}$'),
	egyptLandlinePattern: new RegExp('^(02\\d{8}|03\\d{7})$'),
	SaudiLandLinePattern:new RegExp('^(011|012|013|014|015|016)\\d{7}$')
}

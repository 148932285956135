import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiEndPoint } from 'src/app/shared/constants/apis.constant'

@Injectable({
	providedIn: 'root',
})
export class CustomizingMobileAppService {
	constructor(private http: HttpClient) {}
	UploadMarketPlaceSliderImages(body: any) {
		return this.http.post(ApiEndPoint.UploadStoreSliderImages, body)
	}
	createStoreImage(body: any) {
		return this.http.post(ApiEndPoint.createHomeSliderImageApi, body)
	}
	getSubSubCategoryArray(body: any) {
		return this.http.post(ApiEndPoint.getSubSubCategoryArray, body)
	}

	getMarketPlaceData(CountryId: number) {
		return this.http.get(
			`${ApiEndPoint.getMarketPlaceData}?CountryId=${CountryId}`
		)
	}
	updateStoreImages(body: any) {
		return this.http.put(ApiEndPoint.updateStoreSliderImagesApi, body)
	}
	updateStoreSingleImage(body: any) {
		return this.http.put(ApiEndPoint.updateStoreSingleImage, body)
	}
	deleteStoreImages(id: number) {
		return this.http.delete(
			`${ApiEndPoint.deleteStoreSliderImagesApi}?id=${id}`
		)
	}
	getCities(countryId: number) {
		return this.http.get(`${ApiEndPoint.getCities}?countryId=${countryId}`)
	}

	uploadSalonImage(body: any) {
		return this.http.post(ApiEndPoint.uploadSalonImageApi, body)
	}
	getHomePageDetails(countryId: number) {
		return this.http.get(
			`${ApiEndPoint.getHomePageDetailsApi}?CountryId=${countryId}`
		)
	}
	uploadHomeSliderImage(body: any) {
		return this.http.post(ApiEndPoint.uploadHomeSliderImageApi, body)
	}
	getBranchesByCityWithImage(
		countryId: number,
		SectionId: number,
		searchText: string = '',
		hasOffer: boolean = true,
		targetAudience = null,
		cityId = null
	) {
		let body = {
			countryId,
			cityId,
			SectionId,
			targetAudience,
			searchText,
			hasOffer,
		}

		return this.http.post(`${ApiEndPoint.getBranchesByCityWithImage}`, body)
	}
	getBranchesByCity(
		hasOffer: boolean = false,
		searchText: string,
		countryId: number,
		cityId = null
	) {
		let body = {
			hasOffer,
			searchText,
			countryId,
			cityId,
		}

		return this.http.post(`${ApiEndPoint.getBranchesByCityForSlider}`, body)
	}
	addHomePageSliderImage(body: any) {
		return this.http.post(`${ApiEndPoint.addHomePageSliderImageApi}`, body)
	}
	editHomePageSliderImage(body: any) {
		return this.http.put(`${ApiEndPoint.editHomePageSliderImageApi}`, body)
	}
	deleteHomePageSliderImage(id: any) {
		return this.http.delete(
			`${ApiEndPoint.deleteHomePageSliderImagesApi}?id=${id}`
		)
	}
	updateSection(body: any) {
		return this.http.put(`${ApiEndPoint.updateSectionApi}`, body)
	}
	getSectionDetails(countryId: number, sectionId: number) {
		return this.http.get(
			`${ApiEndPoint.getSectionDetailsApi}?CountryId=${countryId}&SectionId=${sectionId}`
		)
	}
	updateShopBy(body: any) {
		return this.http.put(`${ApiEndPoint.updateShopByApi}`, body)
	}
	getShopByDetails(countryId: any) {
		return this.http.get(
			`${ApiEndPoint.getShopByDetailsApi}?CountryId=${countryId}`
		)
	}
	getServicesCategory(targetAudience: any) {
		return targetAudience
			? this.http.get(
					`${ApiEndPoint.getServicesCategories}?targetAudience${targetAudience}`
			  )
			: this.http.get(`${ApiEndPoint.getServicesCategories}`)
	}
	updateSliderOrder(body: any) {
		return this.http.put(`${ApiEndPoint.updateSliderOrder}`, body)
	}
	getSalonOffer(searchText: string) {
		let body = {
			searchText: searchText,
		}
		return this.http.post(ApiEndPoint.getHomePageSalonOffer, body)
	}
	getProductOffer(searchText: string) {
		let body = {
			searchText: searchText,
		}
		return this.http.post(ApiEndPoint.getHomePageProductOffer, body)
	}
	getSalonHomeData(countryId: Number) {
		return this.http.get(
			`${ApiEndPoint.getSalonHomePageData}?CountryId=${countryId}`
		)
	}
	getProductsHasOffer(body: any) {
		return this.http.post(`${ApiEndPoint.getProductsHasOffer}`, body)
	}
}
